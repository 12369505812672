@media print {
  .print-m-0 {
    margin: 0 !important;
  }
}

.cert-container {
  width: 100%;
  height: 530px;
  display: flex;
  justify-content: center;
}

.cert {
  width: 700px;
  height: 540px;
  padding: 15px 20px;
  text-align: center;
  position: relative;
  z-index: -1;
}

.cert-bg {
  position: absolute;
  left: 0px;
  top: 0;
  z-index: -1;
  width: 100%;
}

.cert-content {
  width: 650px;
  height: 500px;
  padding: 70px;
  text-align: center;
}

.bottom-txt {
  padding: 12px 5px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.bottom-txt * {
  white-space: nowrap !important;
}

.other-font {
  font-family: Cambria, Georgia, serif;
  font-style: italic;
}

.ml-215 {
  margin-left: 215px;
}
