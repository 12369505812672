@import url("https://fonts.googleapis.com/css?family=Space+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Cedarville+Cursive&family=Kurale&family=Meddon&family=Style+Script&display=swap");

.header,
.t-head,
.footer,
.t-foot {
  width: 100%;
  background: #fff;
}

.spacingHeader {
  display: none;
}

.content .page {
  page-break-before: always;
}

.content .pageStyle {
  page-break-after: always;
}

.pageStyle {
  padding: 0px;
}

.signatureDetails {
  display: none;
}

.pageStyles {
  width: 8.5in; 
  height: 11in;
  margin: 0px auto .1in;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  background: white;
  border: 1px solid #ccc;
}



@page {
  page: "A4";
  margin: 1rem;
}

@media print {

  .pageStyles {
    margin: 0px; 
    box-shadow: none !important;
    border: 0 !important;
  }

  .singleFormHeader {
    border-bottom: 1px solid #ddd;
    position: fixed;
    top: 0;
  }

  .cdrgheader {
    position: fixed;
    top: 0;
    border-bottom: 1px solid #ddd;
  }

  .footer{
    position: fixed;
    bottom: 0;
  }

  .spacingHeader {
    display: block;
  }

  .signatureDetails {
    display: block;
  }

  body {
    border: 0;
    margin: 0px; 
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  button {
    display: none;
  }
                                                        
  body {
    margin: 0;
  }

  .PrintHeader {
    padding-top: 300px;
  }
}
