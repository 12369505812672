.spinner {
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle {
  animation: spinners linear infinite;
  position: absolute;
  border: 5px solid transparent;
  border-top-color: #f00;
  border-radius: 50%;
}

.one {
  width: 50px;
  height: 50px;
  border-top-color: #103c5e;
  animation-duration: 0.85s;
}

.two {
  width: 70px;
  height: 70px;
  border-top-color: #166db1;
  animation-duration: 0.95s;
}

.three {
  width: 100px;
  height: 100px;
  border-top-color: #336287;
  animation-duration: 1.05s;
}

.tableSpinner {
  width: 100%;
  height: 27vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@keyframes spinners {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
