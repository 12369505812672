/* LOGIN PAGE.............................. */

.loginuserid,
.loginpassword,
.forgotpassword,
.signintittle,
.galaxsite,
.copyright {
  color: #103c5e;
}

.loginuserid,
.loginpassword,
.forgotpassword {
  color: #103c5e;
  font-size: 14px;
}

.useridinput,
.passwordinput {
  padding-right: 32px !important;
}

.signinbutton {
  background: #103c5e !important;
  color: white !important;
  transition: 1s;

  &:hover {
    background: transparent !important;
    font-weight: 600 !important;
    color: #103c5e !important;
    border: 1px solid #103c5e !important;
  }

  &:focus {
    background: transparent !important;
    border: 1px solid #103c5e !important;
    color: #103c5e !important;
  }
}

.passwordSection {
  position: relative;

  .passwordIcon {
    position: absolute;
    bottom: 7px;
    right: 10px;
  }
}
.Login_Page {
  margin: 0;
  padding: 0;
  display: grid;
  place-content: center;
  min-height: 100vh;
}

.login__page_wrapper {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 8px;
  padding: 30px;
  border-radius: 10px;
}

@media screen and (max-width: 994px) {
  .loginForm {
    width: 40%;
  }
}

@media screen and (max-width: 852px) {
  .copyright,
  .galaxsite {
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .copyright,
  .galaxsite {
    font-size: 11px;
  }

  .loginForm {
    width: 60%;
  }
}

@media screen and (max-width: 424px) {
  .loginuserid,
  .loginpassword,
  .forgotpassword {
    color: #103c5e;
    font-size: 12px;
  }
}

@media screen and (max-width: 324px) {
  .copyright,
  .galaxsite {
    font-size: 8px;
  }
}

.backtoSignin {
  &:hover {
    color: #103c5e;
  }
}
