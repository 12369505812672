.crfBuilder {
  height: calc(100vh - 156px);
  // .Pane {
  //   overflow: hidden !important;
// }
  .builderLeft {
    .save__all__data_action {
      bottom: 4px;
      width: 30px;
      right: 0;
      position: absolute;
    }
    .confirmButton_for_save {
      bottom: 4px;
      width: 170px;
      right: 0;
      position: absolute;
    }

    .crfTabsActive {
      background: #ccd6dd !important;
      color: black !important;
      width: 100%;
      border-radius: 0;
      padding: 5.5px 15px;
    }

    .crfTabs {
      background: transparent;
      border-bottom: 1px solid #dee2e6;
      margin: 0;
      color: black;
      border-radius: 0;
      width: 100%;
      padding: 5.5px 15px;
      &:hover {
        background: #ccd6dd;
      }
    }
  }

  .builderRight {
    border-left: 1px solid #dee2e6;
    margin-left: 5px;

    .builderRightTop {
      height: 30vh;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      span: {
        font-size: 10px;
        color: red;
      }
      label {
        font-size: 15px;
      }
      input {
        max-width: 230px;
      }
      .required {
        color: red;
      }
    }
    .demographic2rightbottom {
      height: calc(100vh - 89px);
      overflow: auto;
    }
  }
}

.btn:active{
  border-color: transparent !important;
}

.w-150 {
  width: 150px;
}

.styles-module_tooltip__mnnfp {
  width: auto !important;
  font-size: 10px !important;
  padding: 5px 17px !important;
  z-index: 9999;
}
