.navBell {
  color: white;
}

.bell {
  border: 1px solid transparent !important;
}

.navSearchContainer {
  margin-right: 14px;
}

.navsearch {
  border-radius: 10px;
  outline: none;
}

.toolsDrops {
  button,
  a {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: baseline;
    // justify-content: space-between;
    gap: 10px;
    padding: 0px 8px;
    font-size: 13px;
    white-space: nowrap;
  }
}

.navbell {
  margin-right: 11px;
  margin-left: 7px;
}

.navbar .nav-item {
  margin: 0px 2px;
}

.navbox {
  background-color: white;
  border-radius: 5px;
}

.navbar .nav-link {
  background: transparent;
  border: none;
  color: #ffffff !important;
  text-decoration: none;
  font-size: 13px;
}

.navcontainer-fluid {
  display: flex;
  width: 100%;
  margin: auto;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.edc-nav .img-dropdown {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.bottomFilebar {
  display: none;
}

.dropdown .Apps_text{
  color: #ffffff;
}

@media screen and (max-width: 991px) {
  .navSearchContainer {
    display: none !important;
  }

  .dropdown .Apps_text{
    color: #000;
  }
  

  .bottomFilebar {
    display: block;

    svg {
      fill: black;
    }

    .searchBar {
      padding: 7px 0px 7px 0px;
    }

    li {
      border-bottom: 1px solid gray;
    }

    .nav-link {
      color: black !important;
    }

    .dropdown-menu.show {
      max-height: 70vh;
      display: block;
      overflow: auto;
      background: white;
      padding: 10px;
    }

    .gridIcon {
      padding: 0px;
    }
  }
}

@media screen and (max-width: 575px) {
  .profilers1 {
    margin-top: 8px !important;
  }
}
