.navSidebarDropdown {
  .dropdown ::after {
    display: none !important;
  }

  display: inline-block;

  .dropdown-toggle {
    border: 1px solid transparent !important;
  }
}

.sidebarGrid {
  position: relative;
}

ul.sidebarList {
  position: absolute;
  background: white;
  border-radius: 6px;
  right: 0px;
  padding: 10px 0px 10px 0px;
  max-width: 240px;
  min-width: 100%;
  border: 1px solid hsl(0, 0%, 82%);
}

ul.sidebarList li {
  color: black;
  padding: 2px 10px 0px 10px;

  &:hover {
    background: #ededed;
  }
}

@media screen and (max-width: 991px) {
  .sidebarDropdownIcon {
    padding-left: 1px;
  }
}
