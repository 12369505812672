.title {
  border-radius: 10px 10px 0 0;
  background: hsl(206, 60%, 30%);
  color: white;
  font-size: 18px;
}

.updateProfile {
  background: #103c5e;
    border: 1px solid #103c5e;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  padding: 3px 10px;
  transition: 1s;
  &:hover {
    background: transparent;
    border: 1px solid #103c5e !important;
    color: #103c5e;
  }
}

.profile-section {
  height: calc(100vh - 121px);
  overflow-y: auto;
  overflow-x: hidden;
}

.profileSection {
  width: 100%;
  height: calc(100vh - 112px);
  margin: 4px 0;
  img{
    width: 50px;
    height: 50px;
    margin: 4px 0;
    border: 1px solid;
  }
}

.img-account-profile {
  height: 10rem;
}

.card .card-header {
  font-weight: 500;
}
.card-header:first-child {
  border-radius: 0.35rem 0.35rem 0 0;
}
.card-header {
  margin-bottom: 0;
  background-color: rgb(31 83 122);
  border: 1px solid rgb(31 83 122);
  color: #fff;
}

.nav-borders .nav-link.active {
  color: #0061f2;
  border-bottom-color: #0061f2;
}
.nav-borders .nav-link {
  color: #69707a;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}